<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card shadow-sm border border-3">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Send your information</small>
          </div>
          <form @submit.prevent="handleSubmit">
            <base-input
              class="input-group-alternative mb-2"
              placeholder="Name"
              v-model="form.name"
              :errors="form.errors.name"
            />
            <base-input
              type="email"
              class="input-group-alternative mb-2"
              placeholder="Email"
              v-model="form.email"
              :errors="form.errors.email"
            />
            <base-input
              class="input-group-alternative mb-2"
              placeholder="Password"
              type="password"
              v-model="form.password"
              :errors="form.errors.password"
            />
            <base-input
              class="input-group-alternative"
              placeholder="Confirm Password"
              type="password"
              v-model="form.password_confirmation"
            />
            <div class="text-center">
              <base-button
                type="primary"
                native-type="submit"
                class="my-4 submit"
                :disabled="form.processing"
              >
                Send
              </base-button>
            </div>
            <p>
              Have an account?
              <router-link to="/login">login</router-link>
            </p>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "register",
  data: () => ({
    form: {
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      errors: {},
      processing: false,
    },
  }),
  methods: {
    async handleSubmit() {
      this.form.errors = {};
      try {
        await axios.post(
          process.env.VUE_APP_API_BASE_URL + "/register",
          this.form
        );
        this.$notify({
          type: "success",
          message: "Information sent successfully.",
        });
      } catch (error) {
        this.form.errors = error.response.data.errors;
        this.$notify({
          type: "danger",
          message: "Please check the form.",
        });
      }
    },
  },
};
</script>
